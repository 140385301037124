import { FunctionComponent } from "react";

import styles from "./center.module.scss";

interface CenterProps {
  children: React.ReactNode;
}

const Center: FunctionComponent<CenterProps> = ({ children }) => {
  return <div className={styles.center}>{children}</div>;
};

export default Center;
