import "antd/dist/reset.css";
import { createRoot } from "react-dom/client";
import { AppContextProvider } from "./context/app-context";
import AppRouter from "./router";
import ThemeProvider from "./theme";

const App = () => {
  return (
    <ThemeProvider>
      <AppContextProvider>
        <AppRouter />
      </AppContextProvider>
    </ThemeProvider>
  );
};

const container = document.getElementById("root");

// create root

const root = createRoot(container!);

// render app

root.render(<App />);
