import _extends from "@babel/runtime/helpers/esm/extends";
import genRadius from './genRadius';
export default function genCommonMapToken(token) {
  var motionUnit = token.motionUnit,
    motionBase = token.motionBase,
    borderRadius = token.borderRadius,
    lineWidth = token.lineWidth;
  return _extends({
    // motion
    motionDurationFast: (motionBase + motionUnit).toFixed(1) + "s",
    motionDurationMid: (motionBase + motionUnit * 2).toFixed(1) + "s",
    motionDurationSlow: (motionBase + motionUnit * 3).toFixed(1) + "s",
    // line
    lineWidthBold: lineWidth + 1
  }, genRadius(borderRadius));
}