import { User } from ".prisma/client";
import { parseRateLimit } from 'ratelimit-header-parser';
import dayjs from "../../common/dayjs";
import { decodeToken, joinPath } from "../../common/utility";
import { ResponseObject } from "../types/response-object";

const host = process.env.API_HOST as string;
const root = joinPath(host, "api");
const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
class HttpClient {
  constructor() { }
  get isOffline() {
    return !navigator.onLine;
  }
  prepareUrl(url: string) {
    // add root url if not present
    if (!url.startsWith(root)) {
      url = joinPath(root, url);
    }
    return url;
  }

  /**
   * Get token from local storage if not expired
   * @param key access_token or refresh_token
   * @returns - null if token is expired or not present, token otherwise
   */
  getToken = (key: string) => {
    const value = localStorage.getItem(key);
    if (value) {
      const { exp }: any = decodeToken(value);
      const now = dayjs.utc().unix();
      try {
        if (now >= exp) {
          localStorage.removeItem(key);
          return null;
        }
        return value;
      } catch (e) { }
    }

    return null;
  };
  async getHeaders() {
    let access_token = this.getToken("access_token");
    let refresh_token = this.getToken("refresh_token");

    if (!access_token && refresh_token) {
      const refreshUrl = joinPath(root, "auth", "refresh");
      try {
        const response = (await (
          await fetch(refreshUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              refresh_token,
            }),
          })
        ).json()) as ResponseObject<{
          access_token: string;
          refresh_token: string;
        }>;

        if (response.success && response.data) {
          access_token = response.data.access_token;
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
        } else {
          throw new Error("Refresh token failed");
        }
      } catch (e) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        access_token = null;
      }
    }
    const header = new Headers();
    header.append("Content-Type", "application/json");
    header.append("Accept", "application/json");
    if (access_token) {
      header.append("Authorization", `Bearer ${access_token}`);
    }
    return header;
  }

  private sendRequest = async <T = any>(
    url: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any,
  ): Promise<ResponseObject<T>> => {
    if (this.isOffline) return Promise.reject(new Error(
      "You are offline. Please check your internet connection."
    ));
    url = this.prepareUrl(url);
    const headers = await this.getHeaders();

    if (!(body instanceof FormData)) {
      body = JSON.stringify(body);
    }

    // delete headers["Content-Type"] if body is form data
    if (body instanceof FormData) {
      headers.delete("Content-Type");
    }
    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      if (response.status === 429) {
        console.log('Rate limit exceeded');
        // handle rate limit
        const rateLimit = parseRateLimit(response);
        if (rateLimit) {
          const { reset } = rateLimit;
          const waitTime = reset ? dayjs.utc(reset).diff(dayjs.utc(), 'milliseconds') : 60 * 1000;
          console.log('Waiting for ', waitTime);
          await wait(waitTime);
          return this.sendRequest<T>(url, method, body) as Promise<ResponseObject<T>>;
        }
      }
      return (await response.json()) as ResponseObject<T>;
    } catch (error) {
      console.log('Error: ', error);
      throw error;
    }
  };


  async get<T = any>(url: string) {
    try {
      return await this.sendRequest<T>(url, "GET");
    } catch (error) {
      throw error;
    }
  }

  async post<T = any>(url: string, body?: any) {
    try {
      return await this.sendRequest<T>(url, "POST", body);
    } catch (error) {
      throw error;
    }
  }

  async put<T = any>(url: string, body?: any) {
    try {
      return await this.sendRequest<T>(url, "PUT", body);
    } catch (error) {
      throw error;
    }
  }

  async delete<T = any>(url: string, data?: any) {
    try {
      return await this.sendRequest<T>(url, "DELETE", data);
    } catch (error) {
      throw error;
    }
  }

  async getUser() {
    const access_token = this.getToken("access_token");
    const refresh_token = this.getToken("refresh_token");
    if (!(access_token && refresh_token)) {
      return null;
    }
    try {
      const url = joinPath("users", "profile");
      const response = await this.get<User>(url);
      if (response.success) {
        return response.data;
      }
    } catch (error) { }
    return null;
  }
}

export default new HttpClient();
