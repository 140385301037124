import { FunctionComponent, useContext } from "react";
import { Navigate } from "react-router-dom";
import NavigateWithRedirect from "./components/NavigateWithRedirect";
import AppContext from "./context/app-context";

interface ProtectedPageProps {
  superAdminOnly?: boolean;
  children: React.ReactNode;
}

const ProtectedPage: FunctionComponent<ProtectedPageProps> = ({
  superAdminOnly = false,
  children,
}) => {
  const { user } = useContext(AppContext);
  if (!user) {
    return <NavigateWithRedirect to="/sign-in" />;
  }
  if (superAdminOnly && !user.is_super_admin) {
    return (
      <Navigate to="/error?code=403&message=You are not authorized to view this page" />
    );
  }
  return <>{children}</>;
};

export default ProtectedPage;
