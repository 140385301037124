import { FunctionComponent } from "react";
import { Navigate } from "react-router-dom";

interface NavigateWithRedirectProps {
  to: string;
}

const NavigateWithRedirect: FunctionComponent<NavigateWithRedirectProps> = ({
  to,
}) => {
  const redirect = window.location.href.replace(window.location.origin, "");
  return (
    <Navigate
      to={{
        pathname: to,
        search: `?redirect=${redirect}`,
      }}
    />
  );
};

export default NavigateWithRedirect;
