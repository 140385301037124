import { Spin } from "antd";
import { FunctionComponent, lazy, Suspense } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from "react-router-dom";
import AppLayout from "./app-layout";
import Center from "./components/center";
import ErrorPage from "./pages/error";
import ProtectedPage from "./protected-page";

const ProfilePage = lazy(() => import("./pages/profile/profile"));
const ProjectAreaCreatePage = lazy(
  () => import("./pages/project-areas/project-area-create")
);
const ProjectAreaListPage = lazy(
  () => import("./pages/project-areas/project-area-list")
);
const ProjectAreaUpdatePage = lazy(
  () => import("./pages/project-areas/project-area-update")
);
const ProjectCreatePage = lazy(() => import("./pages/projects/project-create"));
const ProjectListPage = lazy(() => import("./pages/projects/project-list"));
const ProjectUpdatePage = lazy(() => import("./pages/projects/project-update"));
const UserCreatePage = lazy(() => import("./pages/users/user-create"));
const UserListPage = lazy(() => import("./pages/users/user-list"));
const UserUpdatePage = lazy(() => import("./pages/users/user-update"));
const AppConfigPage = lazy(() => import("./pages/app-config-page"));
const SignInPage = lazy(() => import("./pages/sign-in/sign-in"));
const SignOutPage = lazy(() => import("./pages/sign-out"));
const UserGroupCreatePage = lazy(() => import("./pages/user-groups/user-group-create"));
const UserGroupListPage = lazy(() => import("./pages/user-groups/user-group-list"));
const UserGroupUpdatePage = lazy(() => import("./pages/user-groups/user-group-update"));
const EmailTemplateCreatePage = lazy(() => import("./pages/email-templates/email-template-create"));
const EmailTemplateListPage = lazy(() => import("./pages/email-templates/email-template-list"));
const EmailTemplateUpdatePage = lazy(() => import("./pages/email-templates/email-template-update"));

const DashboardPage = lazy(() => import("./pages/dashboard"));

type LazyElementProps = {
  children: React.ReactNode;
};

const Loader = () => <Center><Spin /></Center>

const LazyElement = ({ children }: LazyElementProps) => {
  return (
    <Suspense
      fallback={<Loader />}
    >
      {children}
    </Suspense>
  );
};

interface AppRouterProps { }

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" />, index: true },
      {
        path: "/sign-in", element: <LazyElement><SignInPage /></LazyElement>
      },
      { path: "/sign-out", element: <LazyElement><SignOutPage /></LazyElement> },
      {
        path: "/dashboard",
        element: (
          <ProtectedPage>
            <LazyElement>
              <DashboardPage />
            </LazyElement>
          </ProtectedPage>
        ),
      },
      {
        path: "/profile",
        element: (
          <ProtectedPage>
            <LazyElement>
              <ProfilePage />
            </LazyElement>
          </ProtectedPage>
        ),
      },
      {
        path: "/project-areas",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage superAdminOnly>
                <LazyElement>
                  <ProjectAreaCreatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage superAdminOnly>
                <LazyElement>
                  <ProjectAreaUpdatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <ProjectAreaListPage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/project-areas/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/projects",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <ProjectCreatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <ProjectUpdatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <ProjectListPage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/projects/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/users",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <UserCreatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <UserUpdatePage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>
                <LazyElement>
                  <UserListPage />
                </LazyElement>
              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/users/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/user-groups",
        children: [
          {
            path: "create",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <UserGroupCreatePage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: ":id",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <UserGroupUpdatePage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: "list",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <UserGroupListPage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: "",
            element: <Navigate to={"list"} />
          }
        ]
      },
      {
        path: "/email-templates",
        children: [
          {
            path: "create",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <EmailTemplateCreatePage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: ":id",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <EmailTemplateUpdatePage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: "list",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <EmailTemplateListPage />
              </LazyElement>
            </ProtectedPage>
          },
          {
            path: "",
            element: <Navigate to={"list"} />
          }
        ]
      },
      {
        path: "/app-configs",
        children: [
          {
            path: "",
            element: <ProtectedPage superAdminOnly>
              <LazyElement>
                <AppConfigPage />
              </LazyElement>
            </ProtectedPage>
          }
        ]
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "*",
        element: (
          <Navigate
            to={{
              pathname: "/error",
              search: "?code=404&message=Page not found",
            }}
          />
        ),
      },
    ],
  },
];

export const Router = createBrowserRouter(routes);

const AppRouter: FunctionComponent<AppRouterProps> = () => {
  return <RouterProvider router={Router} />;
};

export default AppRouter;
