import _extends from "@babel/runtime/helpers/esm/extends";
import genControlHeight from '../shared/genControlHeight';
import defaultAlgorithm from '../default';
import genCompactSizeMapToken from './genCompactSizeMapToken';
import genFontMapToken from '../shared/genFontMapToken';
var derivative = function derivative(token, mapToken) {
  var mergedMapToken = mapToken !== null && mapToken !== void 0 ? mapToken : defaultAlgorithm(token);
  var fontSize = mergedMapToken.fontSizeSM; // Smaller size font-size as base
  var controlHeight = mergedMapToken.controlHeight - 4;
  return _extends(_extends(_extends(_extends(_extends({}, mergedMapToken), genCompactSizeMapToken(mapToken !== null && mapToken !== void 0 ? mapToken : token)), genFontMapToken(fontSize)), {
    // controlHeight
    controlHeight: controlHeight
  }), genControlHeight(_extends(_extends({}, mergedMapToken), {
    controlHeight: controlHeight
  })));
};
export default derivative;