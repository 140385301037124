import { useAppContext } from "../context/app-context";

export default function useUser() {
  const { user } = useAppContext();
  const isAdmin = user?.is_super_admin || user?.user_groups?.some((g) => g.is_admin);
  const isActive = user?.active;
  const isSuperAdmin = user?.is_super_admin;
  const isSignedIn = !!user;

  return { isAdmin, isActive, isSuperAdmin, user, isSignedIn };
}
