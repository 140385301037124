import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import React from 'react';
import { useToken } from '../theme/internal';
export var responsiveArray = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];
var getResponsiveMap = function getResponsiveMap(token) {
  return {
    xs: "(max-width: " + token.screenXSMax + "px)",
    sm: "(min-width: " + token.screenSM + "px)",
    md: "(min-width: " + token.screenMD + "px)",
    lg: "(min-width: " + token.screenLG + "px)",
    xl: "(min-width: " + token.screenXL + "px)",
    xxl: "(min-width: " + token.screenXXL + "px)"
  };
};
export default function useResponsiveObserver() {
  var _useToken = useToken(),
    _useToken2 = _slicedToArray(_useToken, 2),
    token = _useToken2[1];
  var responsiveMap = getResponsiveMap(token);
  // To avoid repeat create instance, we add `useMemo` here.
  return React.useMemo(function () {
    var subscribers = new Map();
    var subUid = -1;
    var screens = {};
    return {
      matchHandlers: {},
      dispatch: function dispatch(pointMap) {
        screens = pointMap;
        subscribers.forEach(function (func) {
          return func(screens);
        });
        return subscribers.size >= 1;
      },
      subscribe: function subscribe(func) {
        if (!subscribers.size) this.register();
        subUid += 1;
        subscribers.set(subUid, func);
        func(screens);
        return subUid;
      },
      unsubscribe: function unsubscribe(paramToken) {
        subscribers["delete"](paramToken);
        if (!subscribers.size) this.unregister();
      },
      unregister: function unregister() {
        var _this = this;
        Object.keys(responsiveMap).forEach(function (screen) {
          var matchMediaQuery = responsiveMap[screen];
          var handler = _this.matchHandlers[matchMediaQuery];
          handler === null || handler === void 0 ? void 0 : handler.mql.removeListener(handler === null || handler === void 0 ? void 0 : handler.listener);
        });
        subscribers.clear();
      },
      register: function register() {
        var _this2 = this;
        Object.keys(responsiveMap).forEach(function (screen) {
          var matchMediaQuery = responsiveMap[screen];
          var listener = function listener(_ref) {
            var matches = _ref.matches;
            _this2.dispatch(_extends(_extends({}, screens), _defineProperty({}, screen, matches)));
          };
          var mql = window.matchMedia(matchMediaQuery);
          mql.addListener(listener);
          _this2.matchHandlers[matchMediaQuery] = {
            mql: mql,
            listener: listener
          };
          listener(mql);
        });
      },
      responsiveMap: responsiveMap
    };
  }, [token]);
}