import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _extends from "@babel/runtime/helpers/esm/extends";
// handle border collapse
function compactItemBorder(token, options) {
  var childCombinator = options.borderElCls ? '> *' : '';
  var hoverEffects = ['hover', options.focus ? 'focus' : null, 'active'].filter(Boolean).map(function (n) {
    return "&:" + n + " " + childCombinator;
  }).join(',');
  return {
    '&-item:not(&-last-item)': {
      marginInlineEnd: -token.lineWidth
    },
    '&-item': _extends(_extends(_defineProperty({}, hoverEffects, {
      zIndex: 2
    }), options.focusElCls ? _defineProperty({}, "&" + options.focusElCls, {
      zIndex: 2
    }) : {}), _defineProperty({}, "&[disabled] " + childCombinator, {
      zIndex: 0
    }))
  };
}
// handle border-radius
function compactItemBorderRadius(prefixCls, options) {
  var _ref2;
  var childCombinator = options.borderElCls ? "> " + options.borderElCls : '';
  return _ref2 = {}, _defineProperty(_ref2, "&-item:not(&-first-item):not(&-last-item) " + childCombinator, {
    borderRadius: 0
  }), _defineProperty(_ref2, '&-item:not(&-last-item)&-first-item', _defineProperty({}, "& " + childCombinator + ", &" + prefixCls + "-sm " + childCombinator + ", &" + prefixCls + "-lg " + childCombinator, {
    borderStartEndRadius: 0,
    borderEndEndRadius: 0
  })), _defineProperty(_ref2, '&-item:not(&-first-item)&-last-item', _defineProperty({}, "& " + childCombinator + ", &" + prefixCls + "-sm " + childCombinator + ", &" + prefixCls + "-lg " + childCombinator, {
    borderStartStartRadius: 0,
    borderEndStartRadius: 0
  })), _ref2;
}
export function genCompactItemStyle(token) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    focus: true
  };
  return _defineProperty({}, token.componentCls + "-compact", _extends(_extends({}, compactItemBorder(token, options)), compactItemBorderRadius(token.componentCls, options)));
}