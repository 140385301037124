import { Button, Result } from "antd";
import { FunctionComponent, useContext } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AppContext from "../context/app-context";

interface ErrorPageProps {}

const ErrorPage: FunctionComponent<ErrorPageProps> = () => {
  const [params] = useSearchParams();
  const { user } = useContext(AppContext);
  const code = params.get("code") || "error";
  const message = params.get("message") || "An error occurred";
  const home = user ? "/dashboard" : "/sign-in";

  return (
    <Result
      status={code as any}
      title={code}
      subTitle={message}
      extra={
        <Link to={home}>
          <Button type="primary">Go Home</Button>
        </Link>
      }
    />
  );
};

export default ErrorPage;
