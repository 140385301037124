import { Image } from "antd";
import { FunctionComponent, useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/app-context";

interface LogoProps {}

const Logo: FunctionComponent<LogoProps> = () => {
  const { user } = useContext(AppContext);
  const home = user ? "/dashboard" : "/sign-in";
  return (
    <Link to={home}>
      <Image
        src={require("../assets/logo.png")}
        alt="GLA Construction Map"
        preview={false}
      />
    </Link>
  );
};

export default Logo;
