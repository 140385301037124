import genFontSizes from './genFontSizes';
var genFontMapToken = function genFontMapToken(fontSize) {
  var fontSizePairs = genFontSizes(fontSize);
  var fontSizes = fontSizePairs.map(function (pair) {
    return pair.size;
  });
  var lineHeights = fontSizePairs.map(function (pair) {
    return pair.lineHeight;
  });
  return {
    fontSizeSM: fontSizes[0],
    fontSize: fontSizes[1],
    fontSizeLG: fontSizes[2],
    fontSizeXL: fontSizes[3],
    fontSizeHeading1: fontSizes[6],
    fontSizeHeading2: fontSizes[5],
    fontSizeHeading3: fontSizes[4],
    fontSizeHeading4: fontSizes[3],
    fontSizeHeading5: fontSizes[2],
    lineHeight: lineHeights[1],
    lineHeightLG: lineHeights[2],
    lineHeightSM: lineHeights[0],
    lineHeightHeading1: lineHeights[6],
    lineHeightHeading2: lineHeights[5],
    lineHeightHeading3: lineHeights[4],
    lineHeightHeading4: lineHeights[3],
    lineHeightHeading5: lineHeights[2]
  };
};
export default genFontMapToken;